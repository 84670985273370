// Imports
// ------
import React from 'react';
import Field from './Field';
import Button from '@parts/Button';
import { Row, Column } from '@waffl';
import { withFormik } from 'formik';
import * as Yup from 'yup';

// Styles
// ------
import {
	Jacket,
	Information,
	InformationTitle,
	InformationMessage,
	InformationButton,
} from './styles';

// Component
// ------
const encode = (data) => {
	return Object.keys(data)
		.map(
			(key) =>
				encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
		)
		.join('&');
};

const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const formikEnhancer = withFormik({
	validationSchema: Yup.object().shape({
		fullName: Yup.string().min(5, 'is too short').required('is required'),
		email: Yup.string().email('is invalid').required('is required'),
		phone: Yup.string()
			.required('is required')
			.min(9, 'is not long enough')
			.matches(phoneRegExp, 'is not valid'),
		message: Yup.string().required('is required'),
	}),

	validateOnBlur: true,
	validateOnChange: true,

	mapPropsToValues: (props) => ({
		fullName: '',
		email: '',
		phone: '',
		message: '',
	}),

	handleSubmit: (values, { resetForm, setStatus, setSubmitting }) => {
		fetch('/?no-cache=1', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: encode({
				'form-name': 'contactForm',
				...values,
			}),
		})
			.then(() => {
				setStatus({ success: true });
				setSubmitting(false);
			})
			.catch(() => {
				setStatus({ error: true });
				setSubmitting(false);
			})
			.finally(() => setSubmitting(false));
	},
});

const ContactForm = (props) => {
	const {
		values,
		touched,
		errors,
		handleChange,
		handleBlur,
		handleSubmit,
		handleReset,
		isSubmitting,
		status,
	} = props;

	return (
		<>
			<Jacket
				name='contactForm'
				data-netlify='true'
				data-netlify-honeypot='bot-field'
				onSubmit={handleSubmit}
			>
				<Row isExpanded>
					<Column small={12} large={6} mpad>
						<Field
							id='fullName'
							type='text'
							label='Full Name'
							placeholder='e.g. John Doe'
							name='fullName'
							value={values.fullName}
							onChange={handleChange}
							onBlur={handleBlur}
							error={touched.fullName && errors.fullName}
						/>
					</Column>

					<Column small={12} large={6} mpad>
						<Field
							id='phone'
							type='tel'
							label='Phone Number'
							placeholder='000-000-000'
							name='phone'
							value={values.phone}
							onChange={handleChange}
							onBlur={handleBlur}
							error={touched.phone && errors.phone}
						/>
					</Column>
				</Row>

				<Row isExpanded>
					<Column small={12} mpad>
						<Field
							id='email'
							type='email'
							label='Email Address'
							placeholder='john.doe@youremail.com'
							name='email'
							value={values.email}
							onChange={handleChange}
							onBlur={handleBlur}
							error={touched.email && errors.email}
						/>
					</Column>

					<Column small={12} mpad>
						<Field
							id='message'
							type='message'
							label='Message'
							placeholder='Type your message here...'
							name='message'
							value={values.message}
							onChange={handleChange}
							onBlur={handleBlur}
							error={touched.message && errors.message}
						/>
					</Column>

					<Column small={12} mpad>
						<Button
							submit
							hasIcon='arrow right'
							disabled={isSubmitting}
						>
							Send Inquiry
						</Button>
					</Column>
				</Row>
			</Jacket>

			{status && status.success && (
				<Information animateIn>
					<InformationTitle>Success</InformationTitle>
					<InformationMessage>
						We’ve recieved your inquiry and aim to get back to you
						within 1-3 days.
					</InformationMessage>
					<InformationButton onClick={handleReset}>
						Start over
					</InformationButton>
				</Information>
			)}

			{status && status.error && (
				<Information animateIn>
					<InformationTitle>Oops!</InformationTitle>
					<InformationMessage>
						Something went wrong... Please try again.
					</InformationMessage>
					<InformationButton onClick={handleReset}>
						Try again
					</InformationButton>
				</Information>
			)}
		</>
	);
};

const MyEnhancedForm = formikEnhancer(ContactForm);

const Form = () => <MyEnhancedForm />;

export default Form;
