// Imports
// ------
import React from 'react';
import Img from 'gatsby-image';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
const BackgroundImage = ({ image }) => (
	<Jacket>
		<Img fluid={image.fluid} />
	</Jacket>
);

export default BackgroundImage;
