// Imports
// ------
import React from 'react';

// Styles
// ------
import { Jacket, Label, Input, Message } from './styles';

// Component
// ------
const Field = ({
	id,
	type,
	placeholder,
	label,
	onChange,
	value,
	name,
	error,
	onBlur,
}) => {
	const sharedProps = {
		type: type,
		id: id,
		placeholder: placeholder,
		required: true,
		onChange: onChange,
		value: value,
		name: name,
		isError: error,
		onBlur: onBlur,
	};

	if (type === 'text')
		return (
			<Jacket>
				<Input {...sharedProps} />
				<Label isError={error}>
					{label} {error && <span>{error}</span>}
				</Label>
			</Jacket>
		);

	if (type === 'email')
		return (
			<Jacket>
				<Input {...sharedProps} />
				<Label isError={error}>
					{label} {error && <span>{error}</span>}
				</Label>
			</Jacket>
		);

	if (type === 'tel')
		return (
			<Jacket>
				<Input {...sharedProps} />
				<Label isError={error}>
					{label} {error && <span>{error}</span>}
				</Label>
			</Jacket>
		);

	if (type === 'message')
		return (
			<Jacket>
				<Message {...sharedProps} />
				<Label isError={error}>
					{label} {error && <span>{error}</span>}
				</Label>
			</Jacket>
		);

	if (!type) return null;
	console.error('You didnt select a type of field');
};

export default React.memo(Field);
