// Imports
// ------
import styled, { css } from 'styled-components';
import { Section } from '@tackl';

// Exports
// ------
const sharedStyles = css`
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`;

export const Jacket = styled(Section)(
	(props) => css`
		${sharedStyles}
		position: fixed;
		overflow: hidden;
		background: ${props.theme.bc1};
		z-index: 0;

		&:after {
			${sharedStyles}
			position: absolute;
			content: '';
			z-index: 1;
			background-color: ${props.theme.bc1};
			opacity: 0.9;
		}

		.gatsby-image-wrapper {
			height: 100%;
		}
	`
);
