// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';
import { pStyles } from '@type';

// Exports
// ------
export const Jacket = styled.div(
	(props) => css`
		display: flex;
		flex-direction: column-reverse;
		background: ${props.theme.bc2};
		margin: 0 0 2.4rem 0;

		${breakup.large` margin: 0 0 3.6rem 0; `}
	`
);

export const Label = styled.label(
	(props) => css`
		font-size: 1.2rem;
		line-height: 140%;
		font-family: ${props.theme.body};
		font-weight: ${props.theme.reg};
		background: ${props.theme.bc2};

		transition: all 0.25s ease-in-out;

		${props.isError &&
		css`
			color: ${props.theme.bc3};
		`}
	`
);

export const Input = styled.input(
	(props) => css`
		${pStyles}
		background: ${props.theme.bc2};
		padding: 1.2rem 0;
		border-bottom: 1px solid ${props.theme.bc1}20 !important;

		&::placeholder {
			color: ${props.theme.bc1}50;
			transition: all 0.5s ease-in-out;
		}

		&:focus {
			+ label {
				opacity: 0.5;
			}

			&::placeholder {
				text-indent: -120rem;
			}
		}

		${props.isError &&
		css`
			color: ${props.theme.bc3};
			border-bottom: 1px solid ${props.theme.bc3} !important;

			&:focus + label {
				opacity: 1;
			}
		`}
	`
);

export const Message = styled.textarea(
	(props) => css`
		${pStyles}
		padding: 1.2rem 0;
		height: 9.6rem;
		max-height: 9.6rem;
		background: ${props.theme.bc2};
		border-bottom: 1px solid ${props.theme.bc1}20 !important;
		resize: none;

		&::placeholder {
			color: ${props.theme.bc1}50;
			transition: all 0.5s ease-in-out;
		}

		&:focus {
			+ label {
				opacity: 0.5;
			}

			&::placeholder {
				text-indent: -120rem;
			}
		}
	`
);
