// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section, H1 } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(
	(props) => css`
		position: relative;
		z-index: 1;
		background: transparent;

		${breakup.medium`
			${props.theme.vh}
		`}

		${breakup.large` min-height: 76.8rem; `}
	`
);

export const Content = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 3.6rem 0;

	${breakup.medium` margin: 6rem 0;`}

	${breakup.large`
		height: 100%;
		margin: 0;
	`}
`;

export const Title = styled(H1)`
	text-align: center;
`;

export const TickerPos = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
`;

export const FormPos = styled.div`
	position: relative;
	z-index: 4;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-bottom: 2.4rem;

	${breakup.medium`
		height: 100%;
		margin-bottom: 0;
	`}
`;
