// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, H2, P } from '@tackl';
import { Sweater } from '@parts/Button/styles';

// Exports
// ------
export const Jacket = styled.form(
	(props) => css`
		position: relative;
		width: 100%;
		background: ${props.theme.bc2};
		padding: 2.4rem 0;

		${breakup.medium` padding: 6rem 3.6rem; `}
		${breakup.large` padding: 6rem calc(1.8rem + 2.4rem); `}
	`
);

export const Information = styled.div(
	(props) => css`
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 12;

		display: flex;
		flex-flow: column;
		justify-content: center;
		align-items: center;

		padding: 12rem;
		background: ${props.theme.bc2};
		text-align: center;

		transform-origin: center center;
		opacity: 0;
		transform: scale(0.75);

		${props.animateIn &&
		css`
			opacity: 1;
			transform: scale(1);
			transition: all 1s ${props.theme.bezzy};
			transition-delay: 1s;
		`}
	`
);

export const InformationTitle = styled(H2)((props) => css``);

export const InformationMessage = styled(P)(
	(props) =>
		css`
			margin: 2.4rem 0;
		`
);

export const InformationButton = styled(Sweater)(
	(props) => css`
		&:hover {
			cursor: pointer;
		}
	`
);
