// Imports
// ------
import React, { useEffect } from 'react';
import Seo from '@parts/Seo';
import BackgroundImage from '@parts/BackgroundImage';
import Hero from '@parts/Contact/Hero';
import Footer from '@parts/Footer';
import { graphql } from 'gatsby';
import { action } from 'mobx';
import { headerState } from '@states/header';

import 'splitting/dist/splitting.css';
import 'splitting/dist/splitting-cells.css';

// Component
// ------
const ContactPage = ({ data, transitionStatus }) => {
	useEffect(() => {
		const setHeader = action(() => {
			headerState.noBg = false;
		});

		setHeader();
	}, []);

	useEffect(() => {
		const Splitting = require('splitting');

		Splitting({
			target: '[data-splitting]',
			by: 'chars',
			key: null,
		});
	}, []);

	return (
		<>
			<Seo
				title={data.contact.seo.title}
				desc={data.contact.seo.desc}
				image={data.contact.seo.image}
			/>

			<BackgroundImage image={data.contact.bg} />

			<Hero />

			<Footer std />
		</>
	);
};

export default ContactPage;

// GraphQL
// ------
export const query = graphql`
	query ContactQuery {
		contact: datoCmsContact {
			seo: seoMeta {
				title
				desc: description
				image {
					url
				}
			}
			bg: backgroundImage {
				fluid(maxHeight: 900, maxWidth: 1440) {
					...GatsbyDatoCmsFluid_noBase64
				}
			}
		}
	}
`;
