// Imports
// ------
import React from 'react';
import Form from '@parts/Contact/Form';
import Ticker from '@parts/Ticker';
import { Row, Column } from '@waffl';
import { headerSize } from '@states/headerSize';
import { tickerSize } from '@states/tickerSize';
import { observer } from 'mobx-react';

// Styles
// ------
import { Jacket, Content, Title, TickerPos, FormPos } from './styles';

// Component
// ------
const Hero = () => (
	<Jacket>
		<Row
			isExpanded
			isEqual
			maxHeightDesktop
			isRelative
			style={{
				paddingTop: headerSize.height,
				paddingBottom: tickerSize.height,
			}}
		>
			<Column
				small={6}
				pushOnSmall={3}
				medium={10}
				pushOnMedium={1}
				large={4}
				mpad
				maxHeightDesktop
			>
				<Content>
					<Title isLight>
						Get in <em>touch</em>
					</Title>
				</Content>
			</Column>

			<Column
				small={12}
				medium={10}
				pushOnMedium={1}
				pushOnLarge={2}
				large={5}
				mpad
				maxHeightDesktop
			>
				<FormPos>
					<div style={{ position: 'relative' }}>
						<Form />
					</div>
				</FormPos>
			</Column>
		</Row>

		<TickerPos>
			<Ticker />
		</TickerPos>
	</Jacket>
);

export default observer(Hero);
